import React from 'react';
import { Link } from "react-router-dom";
import useStore from "../../utils/store";

const Subscription = () => {
  const { user }: any = useStore();
  return (
      <div id="stripePricingTableContainer" style={{ width: '100%' }}>
        {
          React.createElement("stripe-pricing-table", {
            "pricing-table-id": "prctbl_1NDjmxBie3DcI2bCqCbwQw10",
            "publishable-key": "pk_test_51NDjeVBie3DcI2bCSmuPyZk2dwNW0kvnqK9hznqb2joAGqfxN5AweeeLQ02UUv5tP3zU8EG9RsulY17SgcxKKg9600OxIwYBJl",
          })
        }
          <div className="flex flex-col flex-1 items-center justify-center">
            <p className="py-10">Already subscribed? manage your account subscription <Link to={`https://billing.stripe.com/p/login/test_5kA9CJgx35Tz7qo4gg?prefilled_email=${user.userEmail}`}>here</Link></p>
          </div>
      </div>
  );
};

export default Subscription;
