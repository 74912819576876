import React, { useCallback, useEffect, useRef, useState } from "react";
import { BsFillSendFill } from "react-icons/bs";
import { Comment } from "react-loader-spinner";

import useChatBot from "../../../hooks/useChatBot";
import classNames from "../../../utils/classNames";
import sleep from "../../../utils/sleep";
import useStore from "../../../utils/store";
import avatar from "../../../assets/avatar.svg";

export default function Chat() {
  const { listChatBotMessages, createChatBotMessage } = useChatBot();

  const {
    user,
    chatBot,
    activeChatSession,
    activeChatSessionMessages,
    setActiveChatSessionMessages,
  } = useStore();

  const [loading, setLoading] = useState<boolean>(false);
  const [inputMessage, setInputMessage] = useState<string>("");

  const chatWindowAnchor = useRef<any>(null);
  const messageInputRef = useRef<any>(null);

  const { chatBotSessionMetadata } = activeChatSession || {};

  useEffect(() => {
    if (activeChatSession) {
      loadChat();
    }
  }, [activeChatSession]);

  const handleNoMessages = useCallback(() => {
    setActiveChatSessionMessages([]);
    messageInputRef?.current?.focus();
    setLoading(false);
  }, []);

  const loadChat = useCallback(async () => {
    setLoading(true);
    await sleep(250);
    chatWindowAnchor?.current?.scrollIntoView({
      bottom: 0,
      behavior: "smooth",
    });
    const messages: any = await listChatBotMessages();
    if (!messages?.length) return handleNoMessages();
    chatWindowAnchor?.current?.scrollIntoView({
      bottom: 0,
      behavior: "smooth",
    });
    messageInputRef?.current?.focus();
    setLoading(false);
  }, [activeChatSession]);

  const sendMessage = useCallback(
    async (e: any) => {
      e.preventDefault();
      if (loading) return;
      setLoading(true);
      setInputMessage("");
      await createChatBotMessage(inputMessage);
      loadChat();
    },
    [inputMessage, loading]
  );

  const renderMessage = useCallback(
    (payload: any) => {
      const {
        chatBotSessionMessage,
        chatBotSessionMessageId,
        chatBotSessionMessageSource,
      } = payload || {};
      if (!payload) return null;

      const isBot = chatBotSessionMessageSource === 0;
      const isAgent = chatBotSessionMessageSource === 1;
      const chatBotName = isBot ? chatBot.chatBotName : isAgent ? user.userName : chatBotSessionMetadata?.externalUserName || "Anonymous";
      const chatBotLogo = (isBot && chatBot.chatBotLogo) ? `${chatBot.chatBotLogo}` : avatar;
      const alignment = (isBot || isAgent) ? "justify-start" : "justify-end";
      const className = classNames("flex", alignment);
      const flexDisplay = !(isBot || isAgent) ? 'flex-row-reverse' : '';
      const textAlignment = !(isBot || isAgent) ? 'text-right' : '';

      return (
        <div key={`message-${chatBotSessionMessageId}`} className={className}>
          <div
            key={`message-${chatBotSessionMessageId}`}
            className="my-2 flex max-w-[50%]"
          >
            <div className={`flex items-center ${flexDisplay}`}>
              <div className="flex-shrink-0 mx-2">
                <img
                  crossOrigin={"anonymous"}
                  className="w-10 h-10 rounded-full"
                  src={chatBotLogo}
                  alt="User Avatar"
                />
              </div>
              <div className={`flex-grow ${textAlignment}`}>
                <div className="font-medium">
                  {chatBotName}
                </div>
                <div className="text-sm text-gray-500">
                  {chatBotSessionMessage}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    },
    [activeChatSession]
  );

  if (!activeChatSession) return null;

  return (
    <div className="flex flex-col h-80 min-h-full flex-1 p-8 bg-white">
      <div className="border-b-[1px]">
        <h2 className="text-xl font-medium py-3">
          {chatBotSessionMetadata?.externalUserName || "Anonymous"}
        </h2>
      </div>
      <div className="flex-col w-full h-full overflow-y-auto">
        {activeChatSessionMessages?.length > 0 &&
          activeChatSessionMessages?.map(renderMessage)}
        {loading && (
          <div className="flex flex-col items-center">
            <Comment
              visible={true}
              height="80"
              width="80"
              ariaLabel="comment-loading"
              wrapperStyle={{}}
              wrapperClass="comment-wrapper"
              color="#fff"
              backgroundColor="#64748B"
            />
          </div>
        )}
        <div ref={chatWindowAnchor} />
      </div>
      <div className="px-4 pt-8">
        <form className="flex" onSubmit={sendMessage}>
                <div className="flex rounded-full border border-gray-300 overflow-hidden w-full">
                    <input
                        ref={messageInputRef}
                        type="text"
                        className="px-4 py-2 flex-grow focus:outline-none"
                        placeholder="Type a reply..."
                        value={inputMessage}
                        onChange={(e: any) => setInputMessage(e.target.value)}
                    />
                    <button type="submit" className="bg-black hover:bg-black text-white font-bold py-2 px-5 rounded-full">
                        <BsFillSendFill />
                    </button>
                </div>
        </form>
      </div>
    </div>
  );
}
