import { useState } from "react";
import useAuth from "../../hooks/useAuth";

export default function PasswordForgot() {
  const { forgotPassword } = useAuth();

  const [email, setEmail] = useState("");

  const [submitted, setSubmitted] = useState<boolean>(false);

  const sendEmail = async () => {
    const operation = await forgotPassword(email);

    const { message } = operation;
    switch (message) {
      case "Forget password submitted":
        setSubmitted(true);
        break;
      default:
        break;
    }
  };

  const onChange = (e: any) => {
    const {
      value,
      dataset: { property },
    } = e.target;

    switch (property) {
      case "email":
        setEmail(value);
        break;
    }
  };

    if (submitted) {
        return (
            <div className="relative flex flex-col justify-center overflow-hidden min-h-screen thm-background">
                <div className="smMax:m-6 m-[100px] bg-white rounded-md shadow-md">
                    <div className="p-[100px] smMax:p-[60px]">
                        <p className="text-center">Please check your email</p>
                    </div>

                    <div className="p-4 text-center text-sm">
                        <span className="">© 2023 Copyright ChatB2B, </span>
                        <a href="https://digitalrebels.com">By DigitalRebels</a>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="relative flex flex-col justify-center overflow-hidden min-h-screen thm-background">
            <div className="smMax:m-6 m-[100px] bg-white rounded-md shadow-md">
                <div className="p-[100px] smMax:p-[60px]">
                    <h3 className="text-center text-md">Reset Password</h3>
                    <form className="mt-6">
                        <div className="mb-2">
                            <label className="block text-sm font-semibold text-gray-800">
                                Email
                            </label>
                            <input
                                data-property="email"
                                type="email"
                                value={email}
                                className="block w-full px-4 py-2 mt-2 text-brand bg-white border rounded-md focus:outline-none focus:ring focus:ring-opacity-40"
                                onChange={onChange}
                            />
                        </div>
                        <div className="mt-6">
                            <button onClick={sendEmail} className="w-full btn-brand thm-btn text-center">
                                Send email
                            </button>
                        </div>
                    </form>
                </div>

                <div className="p-4 text-center text-sm">
                    <span className="">© 2023 Copyright ChatB2B, </span>
                    <a href="https://digitalrebels.com">By DigitalRebels</a>
                </div>
            </div>
        </div>
    );
}
