import React, {useEffect, useState} from 'react';
import {BsFillSendFill} from "react-icons/bs";

const ChatBotMessage = ({message, isMine}: { message: string; isMine: boolean }) => {
    return (
        <div className={`flex ${isMine ? 'justify-end' : ''} mb-4`}>
            <div
                className={`${
                    isMine ? 'bg-black text-white' : 'bg-gray-200 text-gray-800'
                } py-2 px-4 rounded-md max-w-sm`}
            >
                {message}
            </div>
        </div>
    );
};

const ChatBotMessenger = ({ chatBotName, chatBotLogo }: any) => {
    const [messages, setMessages] = React.useState<string[]>([
        `Hi, I\'m Anna your virtual assistant, how can i help you today?`,
        `Hey Anna, can you help with the following...`
    ]);
    const [inputValue, setInputValue] = React.useState('');

    useEffect(() => {
        setMessages([
            `Hi, I\'m ${chatBotName} your virtual assistant, how can i help you today?`,
            `Hey ${chatBotName}, can you help with the following...`
        ])
    }, [chatBotName])
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (inputValue.trim() !== '') {
            setMessages((prevMessages) => [...prevMessages, inputValue]);
            setInputValue('');
        }
    };

    return (
        <div className="bg-white h-24 min-h-full flex flex-col drop-shadow-lg">
            <div className="bg-black text-white py-4 px-6 font-semibold text-lg text-center">
                Hi 👋, it's {chatBotName} <br/>
                <span className="text-sm">
                    Your virtual assistant
                </span>
            </div>
            <div className="justify-end px-6 py-4 flex flex-col h-full overflow-y">
                {messages.map((message, index) => (
                    <ChatBotMessage key={index} message={message} isMine={index % 2 !== 0}/>
                ))}
            </div>
            <form onSubmit={handleFormSubmit}>
                <div className="bg-white px-6 py-4">
                    <div className="flex rounded-full border border-gray-300 overflow-hidden">
                        <input
                            type="text"
                            className="px-4 py-2 flex-grow focus:outline-none"
                            placeholder="Give it a try! Type a message..."
                            value={inputValue}
                            onChange={handleInputChange}
                        />
                        <button type="submit" className="bg-black hover:bg-black text-white font-bold py-2 px-5 rounded-full">
                            <BsFillSendFill />
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default ChatBotMessenger;
