import { useCallback } from "react";
import useStore from "../utils/store";
import useAPI from "./useAPI";

type IUseOrganization = {
  listPermissions: () => Promise<any>;
  listRoles: () => Promise<any>;
  updatePermission: (item: any, name: string) => Promise<any>;
  updateRole: (item: any, name: string) => Promise<any>;
};

const useOrganization = (): IUseOrganization => {
  const { authToken }: any = useStore();

  const { request } = useAPI();

  const listPermissions = useCallback(async () => {
    const path = "/permission/list";
    const config = {
      token: authToken,
    };

    const operation = await request(path, config);

    const { permissions } = operation || {};

    if (!permissions) return;

    return permissions;
  }, [authToken]);

  const updatePermission = useCallback(
    async (item: any, name: string) => {
      const { permissionId } = item;

      const path = "/permission/update";
      const config = {
        token: authToken,
        data: {
          id: permissionId,
          name: name,
        },
      };

      await request(path, config);
    },
    [authToken]
  );

  const listRoles = useCallback(async () => {
    const path = "/role/list";
    const config = {
      token: authToken,
    };

    const operation = await request(path, config);

    const { roles } = operation || {};

    if (!roles) return;

    return roles;
  }, [authToken]);

  const updateRole = useCallback(
    async (item: any, name: string) => {
      const { roleId } = item;

      const path = "/role/update";
      const config = {
        token: authToken,
        data: {
          id: roleId,
          name,
          permissions: item?.permissions,
        },
      };

      await request(path, config);
    },
    [authToken]
  );

  return {
    listPermissions,
    updatePermission,
    listRoles,
    updateRole,
  };
};

export default useOrganization;
