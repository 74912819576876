import React, { useEffect, useState } from "react";
import { Widget, addResponseMessage } from "react-chat-widget";
import { useParams } from "react-router";

import "react-chat-widget/lib/styles.css";
import useChatBot from "../../../hooks/useChatBot";
import avatar from "../../../assets/avatar.svg";

enum ChatBotStatus {
  DRAFT = "0",
  PUBLISHED = "1",
}

enum ChatBotSessionStatus {
  NEW = 0,
  IMPORTANT = 1,
  URGENT = 2,
  SEVERE = 3,
  RESOLVED = 4,
}

type ChatBot = {
  chatBotId: number;
  chatBotName: string;
  chatBotContext: string;
  chatBotLogo: string;
  chatBotLink?: string;
  chatBotStatus: ChatBotStatus;
};

type ChatBotSession = {
  chatBotSessionId: number;
  chatBotSessionReferenceId: string;
  chatBotSessionStatus: ChatBotSessionStatus;
  chatBotSessionMetadata?: Record<string, any>;
};

const ChatBotWidget = () => {
  const params = useParams();
  const { id } = params || {};

  const { loadChatBotWidget, createChatBotWidgetMessage } = useChatBot();

  const [chatBotSession, setChatBotSession] = useState<null | ChatBotSession>(
    null
  );
  const [chatBot, setChatBot] = useState<null | ChatBot>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (id) {
      loadChatBotWidgetInstance();
    }
  }, [id]);

  useEffect(() => {
    if (chatBotSession && chatBot) {
      addResponseMessage(
        "To get started simply ask any question with the language you prefer. Oh and by the way I'm still learning, so if I can't help you, I'll redirect you to additional resources."
      );
    }
  }, [chatBotSession, chatBot]);

  const loadChatBotWidgetInstance = async () => {
    if (!id) return;
    const operation = await loadChatBotWidget(Number(id));
    const {
      message,
      chatBot: chatBotData,
      chatBotSession: chatBotSessionData,
    } = operation;
    switch (message) {
      case "Chat bot widget instance loaded successfully":
        setChatBot(chatBotData);
        setChatBotSession(chatBotSessionData);
        setLoading(false);
        break;
      default:
        setChatBot({} as ChatBot);
        setChatBotSession({} as ChatBotSession);
        setLoading(false);
        console.error(message);
        break;
    }
  };

  const submitChatBotMessage = async (userMessage: string) => {
    const { chatBotId } = chatBot || {};
    const { chatBotSessionId } = chatBotSession || {};

    if (!chatBotId || !chatBotSessionId) return;

    setLoading(true);
    const operation = await createChatBotWidgetMessage(
      chatBotId,
      chatBotSessionId,
      userMessage
    );

    const { chatBotSessionMessage } = operation;

    setLoading(false);
    return chatBotSessionMessage;
  };

  if (!id && !chatBotSession && !chatBot) {
    return null;
  }

  const handleNewUserMessage = async (newMessage: string) => {
    if (loading) {
      return null;
    }
    const { chatBotSessionMessage } =
      (await submitChatBotMessage(newMessage)) || "";
    addResponseMessage(chatBotSessionMessage);
  };

  return (
    <div>
      <Widget
        title={`Hi 👋, it's ${chatBot?.chatBotName || "Anna"}`}
        subtitle="Your virtual assistant"
        fullScreenMode={false}
        autofocus={true}
        emojis={false}
        profileAvatar={chatBot?.chatBotLogo? `${chatBot.chatBotLogo}` : avatar}
        handleNewUserMessage={handleNewUserMessage}
      />
    </div>
  );
};

export default ChatBotWidget;
