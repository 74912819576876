import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import useOrganization from "../hooks/useOrganization";

export default function Modal({ selectedItem, hideModal }: any) {
  const { action, item } = selectedItem || {};

  const { updatePermission, updateRole } = useOrganization();

  const [name, setName] = useState<string>("");

  useEffect(() => {
    setName(item?.permissionName || item?.roleName || "");
  }, [item]);

  const onClose = () => {
    hideModal(false);
  };

  const update = async () => {
    if (item?.permissionId) {
      await updatePermission(item, name);
    }

    if (item?.roleId) {
      await updateRole(item, name);
    }
  };

  const onSubmit = async () => {
    switch (action) {
      case "Update":
        await update();
        break;
      default:
        break;
    }
    hideModal(true);
  };

  return (
    <Transition appear show={true} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  {action}
                </Dialog.Title>
                <div className="mt-2">
                  <input
                    value={name}
                    onChange={(e: any) => setName(e.target.value)}
                    className="py-4 px-4 bg-slate-100 text-slate-800 focus:outline-none rounded"
                  />
                </div>

                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={onSubmit}
                  >
                    Got it, thanks!
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
