const users = [
  {
    userId: 2,
    userName: "Peter Admin",
    userEmail: "admin@petrusjj.com",
    userResetPasswordToken: null,
    userEmailVerified: true,
    userRoleId: 2,
    createdAt: "2023-05-20T14:15:55.000Z",
    updatedAt: "2023-05-20T14:16:09.000Z",
  },
  {
    userId: 3,
    userName: "Peter User",
    userEmail: "user@petrusjj.com",
    userResetPasswordToken: null,
    userEmailVerified: true,
    userRoleId: 2,
    createdAt: "2023-05-20T14:15:55.000Z",
    updatedAt: "2023-05-20T14:16:09.000Z",
  },
];

const demoData = {
  users,
};

export default demoData;
