import { useCallback, useEffect } from "react";
import { FiPlus } from "react-icons/fi";
import { MdPerson } from "react-icons/md";

import Chat from "./Chat";

import useChatBot from "../../../hooks/useChatBot";
import classNames from "../../../utils/classNames";
import useStore from "../../../utils/store";

const ChatBotSessions = () => {
  const { createChatBotSession, listChatBotSessions } = useChatBot();

  const {
    chatBot,
    chatBotSessions,
    activeChatSession,
    setActiveChatSession,
    setActiveChatSessionMessages,
  } = useStore();

  useEffect(() => {
    listChatBotSessions();
  }, []);

  const setSessionActive = useCallback(
    (chatBotSession: any) => {
      const { chatBotSessionId } = chatBotSession;
      if (chatBotSessionId === activeChatSession?.chatBotSessionId) {
        return;
      }

      setActiveChatSessionMessages([]);
      setActiveChatSession(chatBotSession);
    },
    [activeChatSession]
  );

  const renderSession = useCallback(
    (chatBotSession: any) => {
      const { chatBotSessionId, chatBotSessionMetadata } = chatBotSession || {};
      if (!chatBotSession) return null;

      const isActive = activeChatSession?.chatBotSessionId === chatBotSessionId;
      const background = isActive ? "bg-slate-300" : "bg-slate-100";
      const className = classNames(
        "flex items-center py-2 px-4 text-gray-800 font-medium hover:bg-slate-200 cursor-pointer rounded-lg mr-8 mb-4",
        background
      );

      return (
        <div
          key={`chatbot-${chatBotSessionId}`}
          onClick={() => setSessionActive(chatBotSession)}
          className={className}
        >
          <MdPerson />
          <span className="pl-1">
            {chatBotSessionMetadata?.externalUserName || ""}
          </span>
        </div>
      );
    },
    [activeChatSession]
  );

  if (chatBot && !chatBotSessions?.length) {
    return (
      <div className="flex flex-col flex-1 items-center justify-center">
        <h1 className="py-10">Load your chatbot into your desired webiste,<br/> and wait until someone starts a chat...</h1>
      </div>
    );
  }

  return (
    <div className="flex h-full flex-1">
      <div className="border-r-[1px] pt-16">
        {chatBotSessions.map(renderSession)}
      </div>
      {activeChatSession && <Chat />}
    </div>
  );
};

export default ChatBotSessions;
