import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useStore from "../../utils/store";
import { toastSuccess } from "../../utils/toast";

export default function SignUp() {
  const { setUser, setAuthToken } = useStore();

  const navigate = useNavigate();

  const { signIn } = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const signInUser = async () => {
    const operation = await signIn(email, password);

    const { message, token, user } = operation;

    switch (message) {
      case "Logged in successfully":
        setUser(user);
        setAuthToken(token || null);
        navigate("/admin");
        toastSuccess(`Welcome ${user?.userName || ""}!`);
        break;
      default:
        break;
    }
  };

  const onChange = (e: any) => {
    const {
      value,
      dataset: { property },
    } = e.target;

    switch (property) {
      case "email":
        setEmail(value);
        break;
      case "password":
        setPassword(value);
        break;
    }
  };

  return (
    <div className="relative flex flex-col justify-center overflow-hidden min-h-screen thm-background">
      <div className="smMax:m-6 m-[100px] bg-white rounded-md shadow-md">
        <div className="p-[100px] smMax:p-[60px]">
          <h3 className="text-center text-md">Sign In</h3>
          <form className="mt-6">
            <div className="mb-2">
              <label className="block text-sm font-semibold text-gray-800">
                Email
              </label>
              <input
                  data-property="email"
                  type="email"
                  value={email}
                  className="block w-full px-4 py-2 mt-2 text-black bg-white border rounded-md focus:outline-none focus:ring focus:ring-opacity-40"
                  onChange={onChange}
              />
            </div>
            <div className="mb-2">
              <label className="block text-sm font-semibold text-gray-800">
                Password
              </label>
              <input
                  data-property="password"
                  type="password"
                  value={password}
                  className="block w-full px-4 py-2 mt-2 text-black bg-white border rounded-md focus:outline-none focus:ring focus:ring-opacity-40"
                  onChange={onChange}
              />
            </div>
            <div className="mt-6">
              <a onClick={signInUser} className="w-full btn-brand thm-btn text-center">
                Sign in
              </a>
            </div>
          </form>

          <p className="mt-2 text-xs font-light text-center text-gray-700">
            {" "}
            Forgot your password?{" "}
            <Link
                to="/password-forgot"
                className="font-medium text-gray-500 hover:underline"
            >
              Password forgot
            </Link>
          </p>
        </div>

        <div className="p-4 text-center text-sm">
          <span className="">© 2023 Copyright ChatB2B, </span>
          <a href="https://digitalrebels.com">By DigitalRebels</a>
        </div>
      </div>
    </div>
  );
}
