export default function Pricing() {
    return (
        <section className="mb-32 pricing-section">
            <div className="lg:mx-[200px] p-10 text-center">
                <h2 className="text-[32px] font-semibold mb-4">Pricing</h2>
                <p className="text-[16px] leading-[26px] mb-8 lg:px-[200px] pb-[80px]">
                    Our company offers a pricing model that begins with a free plan, allowing customers to explore
                    our services, and scales up to a full package with blazing fast speed.
                </p>
                <div className="grid px-6 gap-8 md:px-12 lg:grid-cols-3">
                    <div className="single-pricing text-center">
                        <div style={{ position: "relative" }}>
                            <div className="text-center p-6">
                                <h3 className="mb-4 font-bold text-3xl">
                                    <strong>Free</strong>
                                </h3>
                                <p className="mt-[20px] mb-[20px] text-[50px] leading-[1em] font-extralight" style={{
                                    background: "linear-gradient(to right, #6541c1 0%, #d43396 98%, #d43396 100%)",
                                    backgroundClip: "text",
                                    WebkitBackgroundClip: "text",
                                    WebkitTextFillColor: "transparent",
                                }}>
                                    $00
                                </p>
                                <p className="font-semibold">
                                    Limited Access
                                </p>
                            </div>

                            <div className="text-center p-6">
                                <ol className="text-center">
                                    <li className="mb-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth="2"
                                             stroke="currentColor"
                                             className="mr-3 h-5 w-5 text-primary inline-block">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M4.5 12.75l6 6 9-13.5"/>
                                        </svg>
                                        1 Chatbot
                                    </li>
                                    <li className="mb-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth="2"
                                             stroke="currentColor"
                                             className="mr-3 h-5 w-5 text-primary inline-block">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M4.5 12.75l6 6 9-13.5"/>
                                        </svg>
                                        Mail Support
                                    </li>
                                    <li className="mb-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth="2"
                                             stroke="currentColor"
                                             className="mr-3 h-5 w-5 text-primary inline-block">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M4.5 12.75l6 6 9-13.5"/>
                                        </svg>
                                        Phone Support
                                    </li>
                                </ol>
                            </div>

                            <div className="text-center p-6">
                                <button
                                    type="button"
                                    className="rounded-full thm-btn bordered"
                                >
                                    <span style={{position: "relative"}}>Get Started</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="single-pricing text-center popular">
                        <div style={{ position: "relative" }}>
                            <div className="text-center p-6">
                                <h3 className="mb-4 font-bold text-3xl">
                                    <strong>Basic</strong>
                                </h3>
                                <p className="mt-[20px] mb-[20px] text-[50px] leading-[1em] font-extralight" style={{
                                    background: "linear-gradient(to right, #6541c1 0%, #d43396 98%, #d43396 100%)",
                                    backgroundClip: "text",
                                    WebkitBackgroundClip: "text",
                                    WebkitTextFillColor: "transparent",
                                }}>
                                    $29
                                </p>
                                <p className="font-semibold">
                                    Limited Access
                                </p>
                            </div>

                            <div className="text-center p-6">
                                <ol className="text-center">
                                    <li className="mb-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth="2"
                                             stroke="currentColor"
                                             className="mr-3 h-5 w-5 text-primary inline-block">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M4.5 12.75l6 6 9-13.5"/>
                                        </svg>
                                        5 Chatbots
                                    </li>
                                    <li className="mb-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth="2"
                                             stroke="currentColor"
                                             className="mr-3 h-5 w-5 text-primary inline-block">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M4.5 12.75l6 6 9-13.5"/>
                                        </svg>
                                        Mail Support
                                    </li>
                                    <li className="mb-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth="2"
                                             stroke="currentColor"
                                             className="mr-3 h-5 w-5 text-primary inline-block">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M4.5 12.75l6 6 9-13.5"/>
                                        </svg>
                                        Phone Support
                                    </li>
                                </ol>
                            </div>

                            <div className="text-center p-6">
                                <button
                                    type="button"
                                    className="rounded-full thm-btn"
                                >
                                    Get Started
                                </button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="single-pricing text-center">
                            <div style={{position: "relative"}}>
                                <div className="text-center p-6">
                                    <h3 className="mb-4 font-bold text-3xl">
                                        <strong>Pro</strong>
                                    </h3>
                                    <p className="mt-[20px] mb-[20px] text-[50px] leading-[1em] font-extralight" style={{
                                        background: "linear-gradient(to right, #6541c1 0%, #d43396 98%, #d43396 100%)",
                                        backgroundClip: "text",
                                        WebkitBackgroundClip: "text",
                                        WebkitTextFillColor: "transparent",
                                    }}>
                                        $99
                                    </p>
                                    <p className="font-semibold">
                                        Ultimate Access
                                    </p>
                                </div>

                                <div className="text-center p-6">
                                    <ol className="text-center">
                                        <li className="mb-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                 strokeWidth="2"
                                                 stroke="currentColor"
                                                 className="mr-3 h-5 w-5 text-primary inline-block">
                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                      d="M4.5 12.75l6 6 9-13.5"/>
                                            </svg>
                                            1 Chatbot
                                        </li>
                                        <li className="mb-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                 strokeWidth="2"
                                                 stroke="currentColor"
                                                 className="mr-3 h-5 w-5 text-primary inline-block">
                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                      d="M4.5 12.75l6 6 9-13.5"/>
                                            </svg>
                                            Mail Support
                                        </li>
                                        <li className="mb-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                 strokeWidth="2"
                                                 stroke="currentColor"
                                                 className="mr-3 h-5 w-5 text-primary inline-block">
                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                      d="M4.5 12.75l6 6 9-13.5"/>
                                            </svg>
                                            Phone Support
                                        </li>
                                    </ol>
                                </div>

                                <div className="text-center p-6">
                                    <button
                                        type="button"
                                        className="rounded-full thm-btn bordered"
                                    >
                                        <span style={{position: "relative"}}>Get Started</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
