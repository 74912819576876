import { useCallback, useEffect, useMemo, useState } from "react";
import useAPI from "../../hooks/useAPI";
import useStore from "../../utils/store";
import demoData from "../../assets/demoData";
import Table from "../../components/Table";
import Modal from "../../components/Modal";
import Tabs from "../../components/Tabs";
import useOrganization from "../../hooks/useOrganization";

const tabs = ["Permissions", "Roles", "Users"];

export default function Organization() {
  const { listPermissions, listRoles } = useOrganization();

  const [selectedItem, setSelectedItem] = useState<any>(false);
  const [organization, setOrganization] = useState<any>({
    permissions: [],
    roles: [],
    users: demoData.users,
  });

  useEffect(() => {
    listOrganizationData();
  }, []);

  const showModal = ({ action, item }: any) => {
    setSelectedItem({
      action,
      item,
    });
  };

  const hideModal = (updated?: boolean) => {
    setSelectedItem(false);
    if (updated) listOrganizationData();
  };

  const listOrganizationData = useCallback(async () => {
    const promises = [listPermissions(), listRoles()];
    const data = await Promise.all(promises);
    const [permissions, roles] = data;
    setOrganization({ ...organization, permissions, roles });
  }, [organization]);

  const [permissions, roles, users]: any = useMemo(() => {
    if (
      !organization?.permissions?.length ||
      !organization?.roles?.length ||
      !organization?.users?.length
    ) {
      return [[], [], []];
    }

    const permissions = organization?.permissions.reduce(
      (memo: any, permission: any, index: number) => {
        // TODO: handle allowed permissions changes for user
        memo.push({ ...permission, actions: ["Update"] });
        return memo;
      },
      []
    );

    const roles = organization?.roles.reduce(
      (memo: any, role: any, index: number) => {
        // TODO: handle allowed permissions changes for user
        memo.push({ ...role, actions: ["Update"] });
        return memo;
      },
      []
    );

    const users = organization.users;

    return [permissions, roles, users];
  }, [organization]);

  const renderContent = useCallback(
    (tab: string) => {
      switch (tab) {
        case "Permissions":
          return (
            <Table id="permissions" items={permissions} showModal={showModal} />
          );
        case "Roles":
          return <Table id="roles" items={roles} showModal={showModal} />;
        case "Users":
          return <div className="flex flex-col"></div>;
        default:
          return null;
      }
    },
    [organization]
  );

  return (
    <div className="w-full flex-col items-stretch">
      <Tabs id="organization" tabs={tabs} renderContent={renderContent} />
      {selectedItem ? (
        <Modal selectedItem={selectedItem} hideModal={hideModal} />
      ) : null}
    </div>
  );
}
