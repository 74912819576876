import React, {useEffect, useState} from "react";
import useChatBot from "../../../hooks/useChatBot";
import useStore from "../../../utils/store";
import { toastSuccess } from "../../../utils/toast";
import ChatBotMessenger from "./ChatBotMessenger";

const ChatBotConfiguration: React.FC = () => {
  const { chatBot } = useStore();

  const { listChatBots, createChatBot, updateChatBot } = useChatBot();

  const [code, setCode] = useState<null | string>(null);

  useEffect(() => {
    if(chatBot && chatBot?.chatBotId){
      setCode(`<iframe src="https://www.chatb2b.ai/chat-widget/${chatBot?.chatBotId}" width="1000" height="1000" style="position: absolute;width: 1000px;height: 1000px;bottom: 0;right: 0;z-index: 999999999; border: none;"></iframe>`)
    }
  }, [chatBot]);

  const [chatBotName, setChatBotName] = useState(
    chatBot?.chatBotName || "Anna"
  );
  const [chatBotLogo, setChatBotLogo] = useState<null | File>(null);
  const [chatBotContext, setChatBotContext] = useState<null | File>(null);
  const [chatBotStatus, setChatBotStatus] = useState(
    chatBot?.chatBotStatus || 0
  );

  const handleCopy = () => {
    navigator.clipboard.writeText(code || '');
    toastSuccess('Code snippet copied successfully!');
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChatBotName(event.target.value);
  };

  const handleLogoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChatBotLogo((event.target?.files || [])[0]);
  };

  const handleContextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChatBotContext((event.target?.files || [])[0]);
  };

  const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setChatBotStatus(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data: any = {
      chatBotName,
      chatBotLogo,
      chatBotContext,
      chatBotStatus,
    };

    const formData = new FormData();
    if(chatBot?.chatBotId){
      formData.append('id', chatBot.chatBotId);
    }
    if(data.chatBotName){
      formData.append('name', data.chatBotName);
    }
    if(data.chatBotLogo){
      formData.append('logo', data.chatBotLogo);
    }
    if(data.chatBotContext){
      formData.append('context', data.chatBotContext);
    }
    if(data.chatBotStatus){
      formData.append('status', data.chatBotStatus);
    }

    let operation = null;

    if (chatBot?.chatBotId) operation = await updateChatBot(formData);
    else operation = await createChatBot(formData);

    const message = `Chat bot ${
      chatBot?.chatBotId ? "updated" : "created"
    } succesfully`;

    toastSuccess(message);

    await listChatBots();
  };

  return (
      <>
    <div className="flex pt-8">
      <div className="flex-col w-1/2 pe-8 drop-shadow-lg">
        <form onSubmit={handleSubmit} className="space-y-6 bg-white p-8">
          <div>
            <label
              htmlFor="chatBotName"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              ChatBot Name
            </label>
            <input
              type="text"
              name="chatBotName"
              id="chatBotName"
              value={chatBotName}
              onChange={handleNameChange}
              className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
          <div>
            <label
              htmlFor="chatBotLogo"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              ChatBot Logo
            </label>
            <div className="mt-1 flex items-center">
              <label
                htmlFor="logoUpload"
                className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm leading-5 font-medium text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out cursor-pointer"
              >
                Upload
                <input
                  type="file"
                  id="logoUpload"
                  onChange={handleLogoChange}
                  className="sr-only"
                />
              </label>
            </div>
          </div>
          <div>
            <label
              htmlFor="chatBotContext"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              ChatBot Context
            </label>
            <div className="mt-1 flex items-center">
              <label
                htmlFor="contextUpload"
                className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm leading-5 font-medium text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-50 active:text -gray-800 transition duration-150 ease-in-out cursor-pointer"
              >
                Upload
                <input
                  type="file"
                  id="contextUpload"
                  onChange={handleContextChange}
                  className="sr-only"
                />
              </label>
            </div>
          </div>
          <div>
            <label
              htmlFor="chatBotStatus"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              ChatBot Status
            </label>
            <select
              id="chatBotStatus"
              name="chatBotStatus"
              value={chatBotStatus}
              onChange={handleStatusChange}
              className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            >
              <option value="0">Draft</option>
              <option value="1">Published</option>
            </select>
          </div>
          <button
            type="submit"
            className="bg-black hover:bg-black text-white font-bold py-2 px-4 rounded"
          >
            Save
          </button>
        </form>
      </div>

      <div className="flex-col w-1/2">
        <ChatBotMessenger chatBotName={chatBotName} chatBotLogo={chatBotLogo}/>
      </div>
    </div>

  { code && (
      <div className="relative drop-shadow-lg">
        <div className="bg-gray-100 p-4 mt-4 rounded">
              <pre>
                <code className="whitespace-pre-wrap">{code}</code>
              </pre>
          <button className="bg-gray-300 hover:bg-gray-400 text-gray-700 py-2 px-4 rounded mt-2 focus:outline-none" onClick={handleCopy}>
            Copy Code
          </button>
        </div>
      </div>
  )}
  </>
  );
};

export default ChatBotConfiguration;
