import feature2 from "../../assets/responsive-moc.png";
import feature1 from "../../assets/data-moc.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLaptop, faTools } from '@fortawesome/free-solid-svg-icons'

export default function Features() {
    return (
        <section className="mb-32">
            <div className="lg:mx-[200px]">
                <div className="flex flex-wrap items-center">
                    <div className="w-full md:w-1/2">
                        <div className="feature-style-content px-[60px] pt-[70px]">
                            <FontAwesomeIcon className="text-[45px]" icon={faTools} />
                            <h3 className="feature-title">Stay on top of what your customers say</h3>
                            <p className="feature-content">Analytics tools help business to get a real time view of how well your Chat Bot answers your customers queries, and take actions immediately to improve the most relevant questions.</p>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2">
                        <img src={feature1} alt="" />
                    </div>
                </div>

                <div className="flex flex-wrap items-center mt-[135px]">
                    <div className="w-full md:w-1/2">
                        <img src={feature2} className="float-right" alt="" />
                    </div>
                    <div className="w-full md:w-1/2">
                        <div className="feature-style-content px-[60px] pt-[70px]">
                            <FontAwesomeIcon className="text-[45px]" icon={faLaptop} />
                            <h3 className="feature-title">Automate your customer support</h3>
                            <p className="feature-content">With ChatB2B, businesses are able to leverage the knowledge of their customer support teams to automate responses to customer queries more effectively.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
