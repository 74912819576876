import how from "../../assets/how-we-work-slide-1.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLaptop, faCloudDownload, faDiamond, faTools } from '@fortawesome/free-solid-svg-icons'

export default function Solution() {
    return (
        <section className="mb-32">
            <div className="lg:mx-[200px]">
                <div className="flex flex-wrap items-center">
                    <div className="w-full md:w-1/2">
                        <img src={how} className="margin" alt="" style={{ margin: "auto"}}/>
                    </div>
                    <div className="w-full md:w-1/2">
                        <div className="feature-style-content px-[60px] pt-[70px]">
                            <i className="flaticon-responsive-design-symbol"/>
                            <h1 className="pb-16">How does it work?</h1>
                            <ol className="smMax:border-l-0 border-l-2 border-info-100">
                                <li>
                                    <div className="flex-start md:flex">
                                        <div className="smMax:hidden -ml-[40px] flex h-[80px] w-[80px] items-center justify-center rounded-full text-white" style={{
                                            background: "linear-gradient(to right, #6541c1 0%, #d43396 98%, #d43396 100%)"
                                        }}>
                                            <FontAwesomeIcon className="text-3xl" icon={faLaptop} />
                                        </div>
                                        <div
                                            className="mb-10 ml-6 block max-w-md">
                                            <div className="mb-4 flex justify-between">
                                                <h3>Create Account</h3>
                                            </div>
                                            <p className="mb-6 text-neutral-700">
                                                To get started a quick and easy account creation is required
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex-start md:flex">
                                        <div className="smMax:hidden -ml-[40px] flex h-[80px] w-[80px] items-center justify-center rounded-full text-white" style={{
                                            background: "linear-gradient(to right, #6541c1 0%, #d43396 98%, #d43396 100%)"
                                        }}>
                                            <FontAwesomeIcon className="text-3xl" icon={faCloudDownload} />
                                        </div>
                                        <div
                                            className="mb-10 ml-6 block max-w-md">
                                            <div className="mb-4 flex justify-between">
                                                <h3>Upload Basic Account</h3>
                                            </div>
                                            <p className="mb-6 text-neutral-700">
                                                Begin with a basic account and gradually set it up according to your preferences.
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex-start md:flex">
                                        <div className="smMax:hidden -ml-[40px] flex h-[80px] w-[80px] items-center justify-center rounded-full text-white" style={{
                                            background: "linear-gradient(to right, #6541c1 0%, #d43396 98%, #d43396 100%)"
                                        }}>
                                            <FontAwesomeIcon className="text-3xl" icon={faDiamond} />
                                        </div>
                                        <div
                                            className="mb-10 ml-6 block max-w-md">
                                            <div className="mb-4 flex justify-between">
                                                <h3>Train Your Bot</h3>
                                            </div>
                                            <p className="mb-6 text-neutral-700">
                                                By utilizing your own data, you can  train the chatbot to better understand  and respond to your specific needs.
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex-start md:flex">
                                        <div className="smMax:hidden -ml-[40px] flex h-[80px] w-[80px] items-center justify-center rounded-full text-white" style={{
                                            background: "linear-gradient(to right, #6541c1 0%, #d43396 98%, #d43396 100%)"
                                        }}>
                                            <FontAwesomeIcon className="text-3xl" icon={faTools} />
                                        </div>
                                        <div
                                            className="mb-10 ml-6 block max-w-md">
                                            <div className="mb-4 flex justify-between">
                                                <h3>Launch</h3>
                                            </div>
                                            <p className="mb-6 text-neutral-700">
                                                Get started now to try it out. Only when you're  happy we'll ask you to pick a subscription (and   a sign-up button, or keep the top menu floating)
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
