import { useEffect, useState } from "react";
import { FaGlobeAmericas } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

export default function Verify() {
  const { token } = useParams();

  const { verifyEmail } = useAuth();

  const [verified, setVerified] = useState<null | boolean>(null);

  useEffect(() => {
    verifyUser();
  }, []);

  const verifyUser = async () => {
    if (!token) return;

    const operation = await verifyEmail(token);

    const { message } = operation;

    switch (message) {
      case "Email verified successfully.":
        setVerified(true);
        break;
      default:
        console.error(message);
        setVerified(false);
        break;
    }
  };

  if (verified === null) return null;

  if (verified === false) {
    return (
      <div className="flex items-center justify-center">
        <div className="w-full p-6 m-auto bg-white rounded-md shadow-md lg:max-w-xl">
          <span>Error occured</span>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="w-full p-6 m-auto bg-white rounded-md shadow-md lg:max-w-xl">
        <div>
          <div className="mb-4 text-4xl hero-icon">
            <FaGlobeAmericas />
          </div>
          <h4 className="mb-2 text-black">Email verified</h4>
          <p className="text-black">Your email is verified</p>
          <span className="text-black">
            Please{" "}
            <Link to="/sign-in" className="underline">
              sign in
            </Link>
            .
          </span>
        </div>
      </div>
    </div>
  );
}
