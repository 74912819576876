import {useState} from "react";
import {Link} from "react-router-dom";
import logo from "../../assets/chatb2b_logo.png";
import {Transition} from "@headlessui/react";

export default function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const handleToggleMenu = () => setIsMenuOpen(!isMenuOpen);

    return (
        <header className="flex px-5 sm:px-6 lg:mx-[200px]" style={{zIndex: 9999999, position: "relative", top: "0"}}>
            <div className="w-full flex items-center justify-between h-16 md:h-20">
                {/* Site branding */}
                <div className="flex justify-center flex-shrink">
                    <Link to="/" className="">
                        <img src={logo} width={200} alt=""/>
                    </Link>
                </div>

                {/* Hamburger menu */}
                <button className="flex md:hidden" onClick={handleToggleMenu}>
                    <svg
                        className="fill-current h-6 w-6"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <title>Mobile Menu</title>
                        <path d="M0 3h20v2H0zM0 9h20v2H0zM0 15h20v2H0z"/>
                    </svg>
                </button>

                {/* Site navigation */}
                <nav className="hidden md:block flex flex-grow">
                    <ul className="flex flex-grow justify-end flex-wrap items-center">
                        <li>
                            <Link
                                to="/sign-in"
                                className="font-medium text-gray-900 hover:text-gray-500 px-5 py-3 flex items-center transition duration-150 ease-in-out"
                            >
                                Sign in
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/sign-up"
                                className="btn-sm text-gray-200 ml-3"
                            >
                                <button
                                    type="submit"
                                    className="thm-btn"
                                    style={{ background: "#fff"}}
                                >
                                    <span className="thm-btn-span">Sign up</span>
                                </button>
                            </Link>
                        </li>
                    </ul>
                </nav>

                {/* Mobile menu */}
                <Transition show={isMenuOpen}>
                    <div className="fixed z-50 top-0 left-0 w-full">
                        <div className="bg-gray-900 opacity-50 absolute inset-0"/>
                        <div className="bg-white absolute top-0 left-0 w-full px-5">
                            <Link to="/">
                                <img src={logo} width={200} alt=""/>
                            </Link>
                            <button
                                className="absolute top-0 right-0 m-4"
                                onClick={handleToggleMenu}
                            >
                                <svg
                                    className="h-6 w-6 text-gray-600 fill-current"
                                    viewBox="0 0 20 20"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M11.414 10l4.293-4.293a1 1 0 0 0-1.414-1.414L10 8.586 5.707 4.293a1 1 0 0 0-1.414 1.414L8.586 10l-4.293 4.293a1 1 0 1 0 1.414 1.414L10 11.414l4.293 4.293a1 1 0 1 0 1.414-1.414L11.414 10z"
                                    />
                                </svg>
                            </button>
                            <ul className="flex flex-col mt-8">
                                <li>
                                    <Link
                                        to="/sign-in"
                                        className="font-medium text-gray-600 hover:text-gray-100 px-5 py-3 flex items-center transition duration-150 ease-in-out"
                                        onClick={handleToggleMenu}
                                    >
                                        Sign in
                                    </Link>
                                </li>
                                <li className="mt-2">
                                    <Link
                                        to="/sign-up"
                                        className="font-medium text-gray-600 hover:text-gray-100 px-5 py-3 flex items-center transition duration-150 ease-in-out"
                                        onClick={handleToggleMenu}
                                    >
                                        Sign up
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Transition>
            </div>
        </header>
    );
}
