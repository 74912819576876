import { Menu } from "@headlessui/react";
import { FiChevronDown, FiEdit2 } from "react-icons/fi";

export default function ActionDropdown({ item, actions, showModal }: any) {
  const onClickAction = (action: string) => {
    showModal({
      action,
      item,
    });
  };

  return (
    <div className="w-48">
      <Menu>
        <Menu.Button className="inline-flex w-full items-center justify-center rounded-md bg-black bg-opacity-20 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
          <span className="pr-2">Actions</span>
          <FiChevronDown />
        </Menu.Button>
        <div className="absolute bg-white w-72 flex-col right-10 rounded">
          <Menu.Items className="flex flex-col">
            {actions?.map((action: any, index: number) => {
              return (
                <Menu.Item key={`action-${index}`}>
                  {({ active }) => {
                    return (
                      <span
                        onClick={() => onClickAction(action)}
                        className={`px-4 py-4 items-center flex cursor-pointer ${
                          active && "bg-slate-300"
                        }`}
                      >
                        <FiEdit2 />
                        <span className="pl-4">{action}</span>
                      </span>
                    );
                  }}
                </Menu.Item>
              );
            })}
          </Menu.Items>
        </div>
      </Menu>
    </div>
  );
}
