import banner from "../../assets/slider-moc-2.png";

export default function Banner() {
    return (
        <section className="main-banner" style={{position: "relative", top: "-80px"}}>
            <div className="lg:mx-[200px]">
                <div className="flex flex-wrap items-center">
                    <div className="w-full md:w-1/2 pr-[60px]">
                        <div className="feature-style-content px-[60px] pt-[70px]">
                            <i className="flaticon-responsive-design-symbol"/>
                            <h1 className="pb-6 text-[42px] font-semibold leading-[60px]">Create Your Own Chatbot Agent</h1>
                            <p className="text-xl mb-8 text-[16px] leading-[30px]">
                                ChatB2B allows business to quickly create a customer
                                support chat bot that can easily be added to your website.
                            </p>
                            <button
                                type="button"
                                className="rounded-full thm-btn"
                            >
                                JOIN OUR BETA PROGRAM
                            </button>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2">
                        <img src={banner} className="margin" alt="" style={{ margin: "auto"}}/>
                    </div>
                </div>
            </div>
        </section>
    )
}
