import ActionDropdown from "./ActionDropdown";

export default function Table({ id, items, showModal }: any) {
  return (
    <div className="flex flex-col w-full items-stretch">
      {items?.map((item: any, index: number) => {
        const { actions } = item;

        let name;
        switch (id) {
          case "permissions":
            name = item.permissionName;
            break;
          case "roles":
            name = item.roleName;
            break;
          default:
            break;
        }

        return (
          <div
            key={`row-${index}`}
            className="flex flex-row flex-1 w-full items-center hover:bg-slate-100 px-2 py-2 rounded"
          >
            <div className="flex flex-1 w-full">{name}</div>
            <div>
              <ActionDropdown
                item={item}
                actions={actions}
                showModal={showModal}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}
