import React, { useCallback, useEffect, useState } from "react";
import { LineWave } from "react-loader-spinner";

import Tabs from "../../../components/Tabs";
import useChatBot from "../../../hooks/useChatBot";
import useStore from "../../../utils/store";
import ChatBotConfiguration from "./ChatBotConfiguration";
import ChatBotSessions from "./ChatBotSessions";

const tabs = ["Chats", "Configure"];

const ChatBot: React.FC = () => {
  const { listChatBots } = useChatBot();

  const { chatBot } = useStore();

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    initChatBot();
  }, []);

  const initChatBot = useCallback(async () => {
    const operation = await listChatBots();
    if (!operation?.length) {
    }
    setLoading(false);
  }, []);

  const renderContent = useCallback(
    (tab: string) => {
      switch (tab) {
        case "Chats":
          return <ChatBotSessions />;
        case "Configure":
          return <ChatBotConfiguration />;
        default:
          break;
      }
    },
    [chatBot]
  );

  if (loading) {
    return (
      <div className="flex flex-1 w-full items-center justify-center">
        <LineWave
          height="100"
          width="100"
          color="#4fa94d"
          ariaLabel="line-wave"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          firstLineColor=""
          middleLineColor=""
          lastLineColor=""
        />
      </div>
    );
  }

  return (
    <div className="w-full h-full flex-col flex-1">
      <Tabs
        id="chatbot"
        chatBot={chatBot}
        tabs={tabs}
        renderContent={renderContent}
      />
    </div>
  );
};

export default ChatBot;
