import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

const initialState = {
  user: null,
  authToken: null,
  chatBot: null,
  chatBotSessions: null,
  activeChatSession: null,
  activeChatSessionMessages: null,
};

const useStore: any = create<any>(
  persist(
    (set: any, get: any) => ({
      clearStore: () => {
        set((state: any) => initialState);
      },
      user: null,
      setUser: (user: any) => set({ user }),
      authToken: null,
      setAuthToken: (authToken: any) => set({ authToken }),
      chatBot: null,
      setChatBot: (chatBot: any) => set({ chatBot }),
      chatBotSessions: null,
      setChatBotSessions: (chatBotSessions: any) => set({ chatBotSessions }),
      activeChatSession: null,
      setActiveChatSession: (activeChatSession: any) =>
        set({ activeChatSession }),
      activeChatSessionMessages: null,
      setActiveChatSessionMessages: (activeChatSessionMessages: any) =>
        set({ activeChatSessionMessages }),
    }),
    {
      name: "chatb2b-storage", // unique name
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

export default useStore;
