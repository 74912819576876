import { BrowserRouter, Route, Routes } from "react-router-dom";
import Administrator from "./layouts/Administrator";
import Website from "./layouts/Website";
import ChatBotWidget from "./screens/Admin/ChatBot/ChatBotWidget";
import Toast from "./components/Toast";

function App() {
  return (
    <div className="flex flex-col flex-1 h-screen">
      <Toast />
      <BrowserRouter>
        <Routes>
          <Route path="admin/*" element={<Administrator />} />
          <Route path="/*" element={<Website />} />
          <Route path="/chat-widget/:id" element={<ChatBotWidget />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
