import Pricing from "../../components/webiste/Pricing";
import Features from "../../components/webiste/Features";
import Solution from "../../components/webiste/Solution";
import Banner from "../../components/webiste/Banner";
import Header from "../../components/webiste/Header";
import Footer from "../../components/webiste/Footer";

export default function HomePage() {
    return (
        <>
            <Header/>
            <Banner/>
            <Solution/>
            <Features/>
            <Pricing/>
            <Footer/>
        </>
    )
}
