import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

export default function PasswordReset() {
  const { token } = useParams();

  const { resetPassword } = useAuth();

  const [password, setPassword] = useState("");
  const [success, setSuccess] = useState<boolean>(false);

  const reset = async () => {
    if (!token) return;

    const operation = await resetPassword(token, password);

    const { message } = operation;

    switch (message) {
      case "Password reset successfully.":
        setSuccess(true);
        break;
      default:
        console.error(message);
        break;
    }
  };

  const onChange = (e: any) => {
    const {
      value,
      dataset: { property },
    } = e.target;

    switch (property) {
      case "password":
        setPassword(value);
        break;
    }
  };

    if (success) {
        return (
            <div className="relative flex flex-col justify-center overflow-hidden min-h-screen thm-background">
                <div className="smMax:m-6 m-[100px] bg-white rounded-md shadow-md">
                    <div className="p-[100px] smMax:p-[60px]">
                      <p className="text-center">
                        Password is reset. Please{" "}
                          <Link
                              to="/sign-in"
                              className="font-medium text-brand hover:underline"
                          >
                          Sign In
                        </Link>
                        .
                      </p>
                    </div>

                    <div className="p-4 text-center text-sm">
                        <span className="">© 2023 Copyright ChatB2B, </span>
                        <a href="https://digitalrebels.com">By DigitalRebels</a>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="relative flex flex-col justify-center overflow-hidden min-h-screen thm-background">
            <div className="smMax:m-6 m-[100px] bg-white rounded-md shadow-md">
                <div className="p-[100px] smMax:p-[60px]">
                    <h3 className="text-center text-md">Reset Password</h3>

                    <form className="mt-6">
                        <div className="mb-2">
                            <label className="block text-sm font-semibold text-gray-800">
                                Password
                            </label>
                            <input
                                data-property="password"
                                type="password"
                                value={password}
                                className="block w-full px-4 py-2 mt-2 text-brand bg-white border rounded-md focus:outline-none focus:ring focus:ring-opacity-40"
                                onChange={onChange}
                            />
                        </div>
                        <div className="mt-6">
                            <button onClick={reset} className="w-full btn-brand thm-btn text-center">
                                Submit
                            </button>
                        </div>
                    </form>

                    <p className="mt-2 text-xs font-light text-center text-gray-700">
                        {" "}
                        Want to sign in?{" "}
                        <Link
                            to="/sign-in"
                            className="font-medium text-brand hover:underline"
                        >
                            Sign in
                        </Link>
                    </p>
                </div>

                <div className="p-4 text-center text-sm">
                    <span className="">© 2023 Copyright ChatB2B, </span>
                    <a href="https://digitalrebels.com">By DigitalRebels</a>
                </div>
            </div>
        </div>
    );
}
