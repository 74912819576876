import { useCallback, useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import PasswordForgot from "../screens/Auth/PasswordForgot";
import PasswordReset from "../screens/Auth/PasswordReset";
import SignIn from "../screens/Auth/SignIn";
import SignUp from "../screens/Auth/SignUp";
import Verify from "../screens/Auth/Verify";
import useStore from "../utils/store";

import HomePage from "../screens/Webiste/HomePage";

export default function Website() {
  const navigate = useNavigate();

  const { user }: any = useStore();

  useEffect(() => {
    init();
  });

  const init = useCallback(() => {
    if (!user) return;
    navigate("/admin");
  }, [user, navigate]);

  return (
    <div className="flex flex-col items-stretch">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/verify/:token" element={<Verify />} />
          <Route path="/password-forgot" element={<PasswordForgot />} />
          <Route path="/password-reset/:token" element={<PasswordReset />} />
          <Route path="/*" element={<Navigate to="/" replace />} />
        </Routes>
    </div>
  );
}
