import React from "react";

export default function Newsletter() {
    return (
        <section className="py-12 text-black" id="newsletter">
            <div className="lg:mx-[200px] p-10 text-center">
                <h2 className="text-[32px] font-semibold mb-4">Join our beta program</h2>
                <p className="text-[16px] leading-[26px] mb-8">
                    Be one of the first businesses to try out ChatB2B and provide us
                    with valuable feedback to improve our product.
                </p>
                <div className="m-12 rounded-[60px] py-[35px] md:px-[70px] lg:px-[70px] grid-1 grid gap-16 md:grid-cols-2 lg:grid-cols-2" style={{
                    background: "linear-gradient(to right, #6541c1 0%, #d43396 98%, #d43396 100%)"
                }}>
                    <div className="mb-4 flex items-center justify-center md:justify-start">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="white"
                            className="mr-3 h-5 w-5">
                            <path
                                d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                            <path
                                d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                        </svg>
                        <input
                            className="bg-transparent border-b-2 py-2 font-semibold text-white"
                            type="email"
                            placeholder="example@mail.com"
                        />
                    </div>
                    <div className="right smMax:text-center text-right">
                        <button
                            type="button"
                            className="thm-btn"
                            style={{ background: "#fff"}}
                        >
                            <span className="thm-btn-span">Join Now</span>
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
}
