import axios from "axios";
import { toastError } from "../utils/toast";

const api = "https://api.chatb2b.ai";

const useAPI = () => {
  const request = async (
    path: string,
    options: {
      token?: any;
      params?: any;
      data?: any;
      method?: any;
      hasFiles?: boolean
    }
  ) => {
    try {
      const { token, params, data, method, hasFiles } = options;
      const response = await axios({
        headers: {
          "Content-Type": hasFiles ? "multipart/form-data" : "application/json",
          ...(token && { Authorization: `Bearer ${token}` }),
        },
        method: method || "POST",
        url: api + path,
        ...(params && { params }),
        ...(data && { data }),
      });
      console.log(path, response?.data);
      return response.data;
    } catch (e: any) {
      console.error(path, e);
      const message = e?.response?.data?.message || e?.message;

      toastError(message);
      return { message };
    }
  };

  return { request };
};

export default useAPI;
