import { Tab } from "@headlessui/react";
import classNames from "../utils/classNames";

export default function Tabs({ id, tabs, renderContent, chatBot }: any) {
  let defaultIndex = 0;
  if (id === "chatbot" && !chatBot?.chatBotId) defaultIndex = 1;

  return (
    <Tab.Group defaultIndex={defaultIndex}>
      <Tab.List className="flex rounded-xl bg-slate-900/20 mb-8">
        {tabs.map((tab: string) => {
          const isDisabled = defaultIndex === 1;
          return (
            <Tab
              disabled={isDisabled}
              key={tab}
              className={({ selected }: any) => {
                const begin =
                  "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-slate-400 ring-white ring-opacity-60 ring-offset-2 ring-offset-slate-500 focus:outline-none";
                const select = selected ? "bg-white shadow" : "text-slate-100";

                const disabled = isDisabled
                  ? ""
                  : "hover:bg-white/[0.12] hover:text-white";

                const names = classNames(begin, select, disabled);

                return names;
              }}
            >
              {tab}
            </Tab>
          );
        })}
      </Tab.List>
      <Tab.Panels className="flex w-full flex-1">
        {tabs.map((tab: string, index: number) => (
          <Tab.Panel
            key={`tab-${index}`}
            className="rounded-xl ring-white w-full ring-opacity-60 ring-offset-2 ring-offset-slate-400 focus:outline-none"
          >
            {renderContent(tab)}
          </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
}
