import useStore from "../../utils/store";

import { useNavigate } from "react-router-dom";

export default function Profile() {
  const { user, clearStore }: any = useStore();

  const navigate = useNavigate();

  const logout = () => {
    clearStore();
    navigate("/sign-in");
  };

  return (
    <section className="flex flex-col">
      <div>
        <span>Hello {user.userName}!</span>
      </div>

      <button
        className="bg-red-500 text-white px-5 py-2 rounded"
        onClick={logout}
      >
        logout
      </button>
    </section>
  );
}
