import { useCallback, useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import SideBar from "../components/SideBar";
import Dashboard from "../screens/Admin/Dashboard";
import Profile from "../screens/Admin/Profile";
import Subscription from "../screens/Admin/Subscription";
import useStore from "../utils/store";
import ChatBot from "../screens/Admin/ChatBot";
import Organization from "../screens/Admin/Organization";

export default function Administrator() {
  const navigate = useNavigate();

  const { user }: any = useStore();

  useEffect(() => {
    init();
  }, []);

  const init = useCallback(() => {
    if (!user) return navigate("/sign-in");
  }, [user, navigate]);

  if (!user) return null;

  return (
    <div className="flex flex-1 w-full bg-lightPrimary">
      <SideBar />
      <div className="flex w-full px-8 pt-8">
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/subscription" element={<Subscription />} />
          <Route path="/chatbot" element={<ChatBot />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/organization" element={<Organization />} />
          <Route path="/*" element={<Navigate to="/admin" replace />} />
        </Routes>
      </div>
    </div>
  );
}
