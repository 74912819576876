import {
  MdHome,
  MdOutlineShoppingCart,
  MdBarChart,
  MdPerson,
} from "react-icons/md";
import { FcOrganization } from "react-icons/fc";
import { Link, useLocation } from "react-router-dom";

const routes = [
  {
    name: "Dashboard",
    path: "/admin",
    icon: <MdHome className="h-6 w-6" />,
    component: <div />,
  },
  {
    name: "Subscription",
    path: "/admin/subscription",
    icon: <MdOutlineShoppingCart className="h-6 w-6" />,
    component: <div />,
    secondary: true,
  },
  {
    name: "Chatbot",
    path: "/admin/chatbot",
    icon: <MdBarChart className="h-6 w-6" />,
    component: <div />,
  },
  {
    name: "Profile",
    path: "/admin/profile",
    icon: <MdPerson className="h-6 w-6" />,
  },
  {
    name: "Organization",
    path: "/admin/organization",
    icon: <FcOrganization className="h-6 w-6" />,
  },
];

export default function SideBar() {
  useLocation();

  const activeRoute = (routeName: string) => {
    return window.location.pathname === routeName;
  };

  return (
    <section className="bg-white">
      <div className="h-24 flex items-center justify-center">
        <Link to="/admin">
          <div className="font-poppins text-[26px] font-bold uppercase text-navy-700 dark:text-black">
            ChatB2B
          </div>
        </Link>
      </div>
      <div className="h-0.5 bg-lightPrimary w-full" />
      <div className="pt-10">
        {routes.map((route: any, index: number) => {
          return (
            <Link key={index} to={route.path}>
              <div className="relative mb-3 flex hover:cursor-pointer">
                <li
                  className="my-[3px] flex cursor-pointer items-center px-8"
                  key={index}
                >
                  <span
                    className={`${
                      activeRoute(route.path)
                        ? "font-bold text-brand-500 dark:text-black"
                        : "font-medium text-gray-600"
                    }`}
                  >
                    {route.icon ? (
                      route.icon
                    ) : (
                      <svg
                        width="20"
                        height="17"
                        viewBox="0 0 20 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.0001 16V11H12.0001V16C12.0001 16.55 12.4501 17 13.0001 17H16.0001C16.5501 17 17.0001 16.55 17.0001 16V8.99997H18.7001C19.1601 8.99997 19.3801 8.42997 19.0301 8.12997L10.6701 0.599971C10.2901 0.259971 9.7101 0.259971 9.3301 0.599971L0.970098 8.12997C0.630098 8.42997 0.840098 8.99997 1.3001 8.99997H3.0001V16C3.0001 16.55 3.4501 17 4.0001 17H7.0001C7.5501 17 8.0001 16.55 8.0001 16Z"
                          fill="currentColor"
                        />
                      </svg>
                    )}{" "}
                  </span>
                  <p
                    className={`leading-1 ml-4 flex ${
                      activeRoute(route.path)
                        ? "font-bold text-navy-700 dark:text-black"
                        : "font-medium text-gray-600"
                    }`}
                  >
                    {route.name}
                  </p>
                </li>
                {activeRoute(route.path) ? (
                  <div className="absolute right-0 top-px h-9 w-1 rounded-lg bg-black" />
                ) : null}
              </div>
            </Link>
          );
        })}
      </div>
    </section>
  );
}
