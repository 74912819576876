import { useCallback } from "react";
import useStore from "../utils/store";
import useAPI from "./useAPI";

type IUseChatBot = {
  listChatBots: () => Promise<any>;
  createChatBot: (payload: any) => Promise<any>;
  updateChatBot: (payload: any) => Promise<any>;
  listChatBotSessions: () => Promise<any>;
  createChatBotSession: () => Promise<any>;
  listChatBotMessages: () => Promise<any>;
  createChatBotMessage: (message: string) => Promise<any>;
  loadChatBotWidget: (id: number) => Promise<any>;
  createChatBotWidgetMessage: (
    chatBotId: number,
    chatBotSessionId: number,
    chatBotSessionMessage: string
  ) => Promise<any>;
};

const useChatBot = (): IUseChatBot => {
  const {
    authToken,
    chatBot,
    setChatBot,
    setChatBotSessions,
    activeChatSession,
    setActiveChatSession,
    setActiveChatSessionMessages,
  }: any = useStore();

  const { request } = useAPI();

  // chatbot
  const listChatBots = useCallback(async () => {
    const path = "/chatbot/list";
    const config = {
      token: authToken,
    };

    const operation = await request(path, config);

    const { chatBots } = operation || {};

    if (!chatBots) return;

    setChatBot(chatBots);

    return chatBots;
  }, [authToken]);

  const createChatBot = useCallback(
    async (payload: any) => {
      const path = "/chatbot/create";
      const config = {
        token: authToken,
        data: payload,
        hasFiles: true,
      };

      const operation = await request(path, config);

      const { chatBot } = operation || {};

      if (!chatBot) return;

      return chatBot;
    },
    [authToken]
  );

  const updateChatBot = useCallback(
    async (payload: any) => {
      const path = "/chatbot/update";

      const config = {
        token: authToken,
        data: payload,
        hasFiles: true
      };

      const operation = await request(path, config);

      const { chatBot } = operation || {};

      if (!chatBot) return;

      return chatBot;
    },
    [authToken]
  );

  // sessions
  const listChatBotSessions = useCallback(async () => {
    const path = "/chatbot/session/list";
    const config = {
      token: authToken,
      data: {
        chatBotId: chatBot?.chatBotId,
      },
    };

    const operation = await request(path, config);

    const { chatBotSessions } = operation || {};

    if (!chatBotSessions) return;

    setChatBotSessions(chatBotSessions);

    setActiveChatSession(chatBotSessions[chatBotSessions?.length - 1]);
  }, [authToken, chatBot]);

  const createChatBotSession = useCallback(async () => {
    const path = "/chatbot/session/create";
    const config = {
      token: authToken,
      data: {
        chatBotId: chatBot?.chatBotId,
      },
    };

    const operation = await request(path, config);

    const { chatBotSession } = operation || {};

    if (!chatBotSession) return;

    await listChatBotSessions();
  }, [authToken, chatBot]);

  // messages
  const listChatBotMessages = useCallback(async () => {
    const { chatBotSessionId } = activeChatSession;

    const path = "/chatbot/session/message/list";
    const config = {
      token: authToken,
      data: {
        chatBotSessionId,
      },
    };

    const operation = await request(path, config);

    const { chatBotSessionMessages } = operation || {};

    if (!chatBotSessionMessages) return;

    setActiveChatSessionMessages(chatBotSessionMessages);

    return chatBotSessionMessages;
  }, [authToken, activeChatSession]);

  const createChatBotMessage = useCallback(
    async (chatBotSessionMessage: string) => {
      const { chatBotSessionId } = activeChatSession;

      const path = "/chatbot/session/message/create";
      const config = {
        token: authToken,
        data: {
          chatBotSessionId,
          chatBotSessionMessage,
          chatBotSessionMessageSource: 1,
        },
      };

      const operation = await request(path, config);

      if (!operation) return;

      return operation;
    },
    [authToken, activeChatSession]
  );

  // widget
  const loadChatBotWidget = useCallback(async (id: number) => {
    const path = "/chatbot/widget/load";
    const config = {
      data: {
        chatBotId: id,
      },
    };
    const operation = await request(path, config);

    if (!operation) return;

    return operation;
  }, []);

  const createChatBotWidgetMessage = useCallback(
    async (
      chatBotId: number,
      chatBotSessionId: number,
      chatBotSessionMessage: string
    ) => {
      const path = "/chatbot/widget/message";
      const config = {
        data: {
          chatBotId,
          chatBotSessionId,
          chatBotSessionMessage,
        },
      };

      const operation = await request(path, config);

      if (!operation) return;

      return operation;
    },
    []
  );

  return {
    listChatBots,
    createChatBot,
    updateChatBot,
    listChatBotSessions,
    createChatBotSession,
    listChatBotMessages,
    createChatBotMessage,
    loadChatBotWidget,
    createChatBotWidgetMessage,
  };
};

export default useChatBot;
